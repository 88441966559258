:root {
    --white: #FFFFFF;
    --black: #000000;
    --primary-color: #18322F;
    --primary-light-color: #547470;
    --border-color: #D1DAD6;
    --body-color: #FEFBF3;

    --background-white: #f5f4f2;
    --brand-dark-green: #18322e;
    --brand-light-green: #b1d8b7;
    --button-secondary-green: #d1d9d6;
    --card-background-white: #fefbf3;
    --flowkitred: #fc5555;
    --flowkitwhite: #ffffff;
    --gray600: #475466;
    --gray900: #0f1728;
    --health-codedata-viz-medium-risk: #ffb424;
    --health-codedataviz-high-risk: #ff4137;
    --health-codedataviz-lower-risk: #ffcc00;
    --placeholder-text: #53736f;
    --text-black: #151f1d;
    --text-disable: #616161;
    --white: #ffffff;
  
    --font-size-32px: 32px;
    --font-size-l: 24px;
    --font-size-m: 20px;
    --font-size-s: 18px;
    --font-size-xl: 36px;
    --font-size-xs: 16px;
    --font-size-xxl: 48px;
    --font-size-xxs: 10px;
  
    --font-family-brasley-bold: "Brasley-Bold", Helvetica;
    --font-family-brasley-medium: "Brasley-Medium", Helvetica;
    --font-family-brasley-mediumitalic: "Brasley-MediumItalic", Helvetica;
    --font-family-brasley-regular: "Brasley-Regular", Helvetica;
    --font-family-brasley-regularitalic: "Brasley-RegularItalic", Helvetica;
    --font-family-brasley-semibold: "Brasley-SemiBold", Helvetica;
    --font-family-eb_garamond: "EB Garamond", Helvetica;
    --font-family-inter: "Inter", Helvetica;
  
    --background-white: rgba(245, 244, 242, 1);
    --card-background-white: rgba(254, 251, 243, 1);
    --brand-dark-green: rgba(24, 50, 47, 1);
    --brand-light-green: rgba(177, 216, 183, 1);
    --button-secondary-green: rgba(209, 218, 214, 1);
    --placeholder-text: rgba(84, 116, 112, 1);
    --text-black: rgba(21, 31, 29, 1);
    --text-disable: rgba(98, 98, 98, 1);
    --dataviz-fitness: rgba(239, 48, 84, 1);
    --dataviz-diet: rgba(91, 202, 153, 1);
    --dataviz-sleep: rgba(150, 91, 225, 1);
    --dataviz-medicine: rgba(107, 127, 215, 1);
    --health-codedataviz-high-risk: rgba(255, 66, 55, 1);
    --health-codedata-viz-medium-risk: rgba(255, 181, 36, 1);
    --health-codedataviz-lower-risk: rgba(255, 204, 0, 1);
    --health-codedataviz-healthy: rgba(151, 223, 162, 1);
    --health-codedata-viz-call-doctor: rgba(185, 45, 235, 1);
    --flowkitpurple: rgba(123, 97, 255, 1);
    --flowkitcharcoal: rgba(34, 34, 34, 1);
    --flowkitred: rgba(252, 85, 85, 1);
    --flowkitgreen: rgba(41, 204, 106, 1);
    --flowkitblue: rgba(0, 153, 255, 1);
    --flowkitwhite: rgba(255, 255, 255, 1);
    --uicolorprimary: rgba(103, 80, 163, 1);
    --display-68-font-family: "Brasley-SemiBold", Helvetica;
    --display-68-font-weight: 600;
    --display-68-font-size: 68px;
    --display-68-letter-spacing: 0px;
    --display-68-line-height: 120.00000476837158%;
    --display-68-font-style: normal;
    --display-40-font-family: "Brasley-Bold", Helvetica;
    --display-40-font-weight: 700;
    --display-40-font-size: 40px;
    --display-40-letter-spacing: 0.8px;
    --display-40-line-height: 50px;
    --display-40-font-style: normal;
    --display-32-font-family: "Brasley-SemiBold", Helvetica;
    --display-32-font-weight: 600;
    --display-32-font-size: 32px;
    --display-32-letter-spacing: 0px;
    --display-32-line-height: 139.9999976158142%;
    --display-32-font-style: normal;
    --title-bold-24-font-family: "Brasley-SemiBold", Helvetica;
    --title-bold-24-font-weight: 600;
    --title-bold-24-font-size: 24px;
    --title-bold-24-letter-spacing: 0px;
    --title-bold-24-line-height: 139.9999976158142%;
    --title-bold-24-font-style: normal;
    --text-large-20-font-family: "Brasley-Medium", Helvetica;
    --text-large-20-font-weight: 500;
    --text-large-20-font-size: 20px;
    --text-large-20-letter-spacing: 0px;
    --text-large-20-line-height: 139.9999976158142%;
    --text-large-20-font-style: normal;
    --text-regular-18-font-family: "Brasley-Medium", Helvetica;
    --text-regular-18-font-weight: 500;
    --text-regular-18-font-size: 18px;
    --text-regular-18-letter-spacing: 0px;
    --text-regular-18-line-height: 139.9999976158142%;
    --text-regular-18-font-style: normal;
    --text-bold-18-font-family: "Brasley-Bold", Helvetica;
    --text-bold-18-font-weight: 700;
    --text-bold-18-font-size: 18px;
    --text-bold-18-letter-spacing: 0px;
    --text-bold-18-line-height: 139.9999976158142%;
    --text-bold-18-font-style: normal;
    --text-small-16-font-family: "Brasley-Medium", Helvetica;
    --text-small-16-font-weight: 500;
    --text-small-16-font-size: 16px;
    --text-small-16-letter-spacing: 0px;
    --text-small-16-line-height: 139.9999976158142%;
    --text-small-16-font-style: normal;
    --text-tiny-12-font-family: "Brasley-SemiBold", Helvetica;
    --text-tiny-12-font-weight: 600;
    --text-tiny-12-font-size: 12px;
    --text-tiny-12-letter-spacing: 0px;
    --text-tiny-12-line-height: 139.9999976158142%;
    --text-tiny-12-font-style: normal;
    --test-website-small-title-font-family: "Brasley-Bold", Helvetica;
    --test-website-small-title-font-weight: 700;
    --test-website-small-title-font-size: 24px;
    --test-website-small-title-letter-spacing: 0px;
    --test-website-small-title-line-height: 139.9999976158142%;
    --test-website-small-title-font-style: normal;
    --test-website-big-title-font-family: "Brasley-SemiBold", Helvetica;
    --test-website-big-title-font-weight: 600;
    --test-website-big-title-font-size: 36px;
    --test-website-big-title-letter-spacing: -0.72px;
    --test-website-big-title-line-height: 139.9999976158142%;
    --test-website-big-title-font-style: normal;
    --test-website-paragraph-font-family: "Brasley-Medium", Helvetica;
    --test-website-paragraph-font-weight: 500;
    --test-website-paragraph-font-size: 20px;
    --test-website-paragraph-letter-spacing: 0px;
    --test-website-paragraph-line-height: 139.9999976158142%;
    --test-website-paragraph-font-style: normal;
    --large-text-24-font-family: "Brasley-Medium", Helvetica;
    --large-text-24-font-weight: 500;
    --large-text-24-font-size: 24px;
    --large-text-24-letter-spacing: 0px;
    --large-text-24-line-height: 139.9999976158142%;
    --large-text-24-font-style: normal;
    --test-website-big-title-underline-font-family: "Brasley-SemiBold", Helvetica;
    --test-website-big-title-underline-font-weight: 600;
    --test-website-big-title-underline-font-size: 36px;
    --test-website-big-title-underline-letter-spacing: -0.72px;
    --test-website-big-title-underline-line-height: 139.9999976158142%;
    --test-website-big-title-underline-font-style: normal;
    --shadow: 0px 4px 8px 0px rgba(112, 136, 210, 0.06);
    --shadow-for-drawer: 0px -3px 4px 0px rgba(24, 50, 47, 0.1);
    --shadow-green: 0px 2px 8px 0px rgba(14, 37, 35, 0.1);
    --green-shadow: 0px 0px 0.10000000149011612px 2px rgba(235, 255, 237, 1), 0px 1px 2px 0px rgba(22, 40, 16, 0.06),
      0px 1px 3px 0px rgba(19, 40, 16, 0.1);
  }
  .display-32 {
    font-family: var(--font-family-brasley-semibold);
    font-size: var(--font-size-32px);
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0;
  }
  
  .title-bold-24 {
    font-family: var(--font-family-brasley-semibold);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.4;
  }
  
  .text-large-20 {
    font-family: var(--font-family-brasley-medium);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0;
  }
  
  .text-regular-18 {
    font-family: var(--font-family-brasley-medium);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0;
  }
  
  .text-bold-18 {
    font-family: var(--font-family-brasley-bold);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0;
  }
  
  .text-small-16 {
    font-family: var(--font-family-brasley-medium);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0;
  }
  
  .test-website-small-title {
    font-family: var(--font-family-brasley-bold);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0;
  }
  
  .test-website-big-title {
    font-family: var(--font-family-brasley-semibold);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.72px;
  }
  
  .text-mdregular {
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0;
  }
  
  .ebgaramond-medium-cutty-sark-16px {
    color: var(--placeholder-text);
    font-family: var(--font-family-eb_garamond);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 500;
  }
  
  .brasley-semi-bold-cutty-sark-10px {
    color: var(--placeholder-text);
    font-family: var(--font-family-brasley-semibold);
    font-size: var(--font-size-xxs);
    font-style: normal;
    font-weight: 600;
  }
  
  .ebgaramond-medium-cutty-sark-10px {
    color: var(--placeholder-text);
    font-family: var(--font-family-eb_garamond);
    font-size: var(--font-size-xxs);
    font-style: normal;
    font-weight: 500;
  }
  
  .brasley-bold-fiord-20px {
    color: var(--gray600);
    font-family: var(--font-family-brasley-bold);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
  }
  
  .brasley-bold-timber-green-36px {
    color: var(--brand-dark-green);
    font-family: var(--font-family-brasley-bold);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 700;
  }
  
  .brasley-semi-bold-black-20px {
    color: var(--black);
    font-family: var(--font-family-brasley-semibold);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 600;
  }
  
  .brasley-regular-normal-bianca-36px {
    color: var(--card-background-white);
    font-family: var(--font-family-brasley-regular);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
  }
  
  .brasley-semi-bold-black-14-4px {
    color: var(--black);
    font-family: var(--font-family-brasley-semibold);
    font-size: 14.4px;
    font-style: normal;
    font-weight: 600;
  }
  
  .brasley-semi-bold-bianca-48px {
    color: var(--card-background-white);
    font-family: var(--font-family-brasley-semibold);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 600;
  }
  
  .brasley-semi-bold-black-9-3px {
    color: var(--black);
    font-family: var(--font-family-brasley-semibold);
    font-size: 9.3px;
    font-style: normal;
    font-weight: 600;
  }
  
  .brasley-semi-bold-black-7px {
    color: var(--black);
    font-family: var(--font-family-brasley-semibold);
    font-size: 7px;
    font-style: normal;
    font-weight: 600;
  }
  
  .brasley-semi-bold-black-13-8px {
    color: var(--black);
    font-family: var(--font-family-brasley-semibold);
    font-size: 13.8px;
    font-style: normal;
    font-weight: 600;
  }
  
  .brasley-bold-wild-sand-20px {
    color: var(--background-white);
    font-family: var(--font-family-brasley-bold);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
  }
  
  .brasley-semi-bold-fiord-20px {
    color: var(--gray600);
    font-family: var(--font-family-brasley-semibold);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 600;
  }
  
  .brasley-regular-normal-timber-green-20px {
    color: var(--brand-dark-green);
    font-family: var(--font-family-brasley-regular);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
  }
  
  .brasley-regular-normal-bianca-18px {
    color: var(--card-background-white);
    font-family: var(--font-family-brasley-regular);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
  }
  
  .brasley-semi-bold-black-36px {
    color: var(--black);
    font-family: var(--font-family-brasley-semibold);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 600;
  }
  
  .brasley-bold-bianca-36px {
    color: var(--card-background-white);
    font-family: var(--font-family-brasley-bold);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 700;
  }
  
  .brasley-bold-timber-green-20px {
    color: var(--brand-dark-green);
    font-family: var(--font-family-brasley-bold);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
  }
  
  .brasley-semi-bold-black-21-7px {
    color: var(--black);
    font-family: var(--font-family-brasley-semibold);
    font-size: 21.7px;
    font-style: normal;
    font-weight: 600;
  }
  .m-0{
    margin: 0 !important;
  }
  .mb-0{
    margin-bottom: 0 !important;
  }
  .mt-0{
    margin-top: 0 !important;
  }
  .mt-10{
    margin-top: 10px !important;
  }
  .mt-15{
    margin-top: 15px !important;
  }
  .mt-20{
    margin-top: 20px !important;
  }
  .mt-25{
    margin-top: 25px !important;
  }
  .mt-30{
    margin-top: 30px !important;
  }
  .mt-35{
    margin-top: 35px !important;
  }
  .mt-40{
    margin-top: 40px !important;
  }
