/* Import the variables from variables.css */
@import "variables.css";

body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-color);
}

:where(.css-dev-only-do-not-override-jmgo4z)[class^="ant-layout"],
:where(.css-dev-only-do-not-override-jmgo4z)[class*=" ant-layout"] {
  font-family: "Inter", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Inter", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.align-left {
  text-align: left !important;
}

.align-right {
  text-align: right !important;
}

.align-center {
  text-align: center !important;
}

.w-100 {
  width: 100% !important;
}

/* Login page */
.login-container {
  flex: 0 0 auto;
  position: relative;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 40px 80px 32px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.login-box {
  max-width: 480px;
  padding: 0 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.login-box .login-box-header {
  margin-bottom: 10px;
  text-align: center;
}

.login-box .login-title {
  text-align: center;
  margin: 0 0 15px 0;
}

.login-box .login-box-content {
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
}

.login-box .login-box-content .ant-card-body {
  padding: 15px 24px;
}

.login-btn.ant-btn {
  width: 100%;
  max-width: 335px;
  height: auto !important;
  padding: 19px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 2px solid var(--Brand-Dark-Green, #18322f);
  background: var(--Brand-Dark-Green, #18322f);
  color: var(--Card-Background-White, #fefbf3);
  box-shadow: none;
  /* font-family: Brasley; */
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.login-btn.ant-btn-lg {
  min-height: 69px !important;
}
.ant-btn {
  font-family: inherit;
}
.or-text {
  text-align: center;
  position: relative;
  margin: 20px 0 16px 0;
}

.or-text:after {
  content: "";
  background-color: #18322f;
  height: 1px;
  width: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.or-text span {
  display: inline-block;
  position: relative;
  background-color: #fff;
  z-index: 1;
  padding: 0 5px;
}

.bottom-info {
  flex: 0 0 auto;
  position: relative;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 0px 80px 96px;
  width: 100%;
}

.bottom-info .in-case-of-any-issue {
  color: var(--black);
  font-weight: 400;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  font-family: var(--font-family-brasley-medium);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
}

.bottom-info .in-case-of-any-issue a {
  color: var(--black);
  font-family: var(--font-family-brasley-bold);
  font-weight: 700;
  letter-spacing: 0;
}

.bottom-info .in-case-of-any-issue a:hover {
  color: var(--primary-light-color);
}

.login-info-text {
  text-align: center;
  color: var(--primary-light-color);
  font-size: 12px;
  max-width: 300px;
  margin: 20px auto 0 auto;
}

.login-info-text a {
  text-decoration: underline;
}

.login-info-text a:hover {
  text-decoration: none;
}

.login-info-text .strong {
  font-weight: 700;
}

.login-info-text .link2 {
  color: var(--primary-color);
  font-size: 16px;
}

.button-section {
  padding-top: 15px;
}

/* Verify Result Box */
.login-container.verify-container {
  height: calc(100vh - 110px);
  justify-content: center;
}

.verify-result-box .ant-result-title {
  color: var(--Brand-Dark-Green, #18322f);
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 35px */
}

.verify-result-box .ant-result-subtitle {
  padding: 0 40px;
  color: var(--Brand-Dark-Green, #18322f);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 21.6px */
}

.verify-result-box .ant-result-extra {
  margin-top: 50px;
}

.verify-result-box .ant-result-extra .bottom-link {
  color: var(--Placeholder-Text, #547470);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 21.6px */
  text-decoration: underline;
  cursor: pointer;
}

.verify-result-box .ant-result-extra .bottom-link:hover {
  text-decoration: none;
}

/* Header CSS */
.app-header {
  background-color: var(--primary-color);
  height: auto;
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
}

.app-header-container {
  width: 100%;
  max-width: 1440px;
  padding: 34px 30px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app-header-container .logo img {
  display: block;
}

/* Main Content CSS */
.main-content {
  min-height: calc(100vh - 129px);
  background-color: var(--body-color);
}

.main-content-after-login {
  min-height: calc(100vh - 129px);
  background-color: #f5f4f2;
}

.container {
  width: 100%;
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;
}

.section-title {
  margin: 0;
  font-size: 32px !important;
}

.block-title-box {
  margin-bottom: 30px;
}

.block-title {
  font-size: 25px !important;
}

.block-subtitle {
  font-size: 18px !important;
  color: var(--primary-color);
}

/* White Box CSS */
.white-box {
  flex: 0 0 auto;
  position: relative;
  align-items: center;
  align-self: stretch;
  background-color: var(--flowkitwhite);
  border: 2px solid;
  border-color: var(--button-secondary-green);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 76px;
  padding: 96px 20px;
  width: 100%;
}

.white-box-content {
  width: 100%;
  max-width: 500px;
}

.white-box.verify-box {
  padding: 50px 20px 76px 20px;
  max-width: 1000px;
  margin: 0 auto;
}

/* Footer CSS */
.footer {
  background-color: #18322f;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  color: #fff;
  text-align: center;
}

.footer p {
  padding: 0;
  margin: 0;
}

/* Form CSS */
input::placeholder {
  color: #547470 !important;
  font-style: normal;
}

input.ant-input.ant-input-lg::placeholder {
  font-size: 18px;
  font-weight: 700;
}

.ant-input {
  border-radius: 8px;
  border: 1px solid var(--Button-Secondary-Green, #d1dad6);
  background: var(--White, #fff);
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.ant-input.ant-input-lg {
  display: flex;
  padding: 22px 25px;
  align-items: center;
}

.ant-input-password .ant-input.ant-input-lg {
  padding: 16px 12px;
}

.input-col2-left {
  display: inline-block;
  width: calc(50% - 10px);
  margin-right: 10px;
}

.input-col2-right {
  display: inline-block;
  width: calc(50% - 10px);
  margin-left: 10px;
}

/* Responsive CSS */
@media screen and (max-width: 767px) {
  .app-header-container {
    padding: 16px 20px;
  }

  .section-title {
    font-size: 22px !important;
  }

  .login-container {
    padding: 20px 20px 20px;
  }

  .white-box {
    padding: 40px 20px;
  }

  .bottom-info {
    padding: 0px 20px 75px;
  }

  .bottom-info .in-case-of-any-issue {
    font-size: 16px;
    line-height: 1.5;
  }

  .block-title {
    font-size: 23px !important;
    margin-bottom: 5px !important;
  }

  .block-subtitle {
    font-size: 14px !important;
    line-height: 1.3;
  }

  .login-btn.ant-btn {
    padding: 10px 16px;
  }

  .login-btn.ant-btn-lg {
    min-height: 54px !important;
  }

  .ant-input.ant-input-lg {
    padding: 16px 25px;
  }

  .button-section {
    padding-top: 0;
  }
}

.ml-20 {
  margin-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.page-header {
  margin: 0;
  padding: 0 0 35px 0;
  display: flex;
  align-items: center;
}

.page-header .page-title {
  margin: 0 0 8px 0;
  color: var(--primary-color);
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  /* 122.222% */
  letter-spacing: -0.72px;
}

.page-header .page-description {
  margin: 0;
  color: var(--primary-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 150% */
}

.page-header .page-header-right {
  margin-left: auto;
}

.page-header .ant-btn-default {
  border: none;
  border-radius: 4px;
  background-color: #fff;
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  height: 44px;
}

.page-header .ant-btn-default .ant-btn-icon {
  font-size: 20px;
}

.block-header {
  margin: 0;
  padding: 0 0 20px 0;
}

.block-header .block-title {
  margin: 0 0 6px 0;
  color: var(--primary-color);
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  /* 150% */
}

.block-header .block-description {
  margin: 0;
  color: var(--primary-color);
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

.block-header .block-description a {
  color: #176448;
  font-weight: 700;
  text-decoration: underline;
}

.block-header .block-description a:hover {
  text-decoration: none;
}

/* Dashboard CSS */
.dashboard-wrapper {
  max-width: 1440px;
  width: 100%;
  padding: 40px 30px;
}

.dashboard-section {
  padding-bottom: 45px;
}

.report-summary-box {
  background-color: #fefbf3;
  border-radius: 4px;
  padding: 32px;
}

.report-summary-box .title {
  margin: 0 0 8px 0;
  padding: 0;
  color: var(--primary-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
}

.report-summary-box .description {
  margin: 0;
  padding: 0;
  color: rgba(24, 50, 47, 0.8);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.faq-section .block-header .block-title {
  color: #626262;
}

.faq-section .block-header .block-description {
  color: #626262;
}

.faq-section .block-header a {
  color: #176448;
  font-weight: 700;
}

.risk-card .img {
  width: Fill (352px);
  background-color: #ffffff;
  padding: 16px 16px 32px 16px;
  text-align: center;
  border-radius: 8px;
  gap: 10px;
}

.risk-card .title {
  margin-bottom: 10px;
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  padding: 0 2rem;
}

.risk-card .title strong {
  font-weight: 700;
}

.risk-card p {
  margin-bottom: 10px;
}

/* Health Collapse CSS */
.health-collapse {
  background: transparent;
  border: none;
  border-radius: 0;
}

.health-collapse .ant-collapse-item {
  margin-bottom: 16px;
  background: #fff;
  border: none;
  border-radius: 4px;
}

.health-collapse .ant-collapse-item .ant-collapse-header {
  padding: 32px;
  justify-content: center;
  align-items: center;
}

.health-collapse .ant-collapse-item .ant-collapse-header h3,
.health-collapse .ant-collapse-item .ant-collapse-header h4,
.health-collapse .ant-collapse-item .ant-collapse-header p {
  margin: 0;
}

.health-collapse
  .ant-collapse-item
  .ant-collapse-header
  .ant-collapse-header-text {
  max-width: 331px;
}

.health-collapse
  .ant-collapse-item
  .ant-collapse-header
  .ant-collapse-header-text
  h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #18322f;
  padding-bottom: 6px;
}

.health-collapse
  .ant-collapse-item
  .ant-collapse-header
  .ant-collapse-header-text
  p {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: rgba(24, 50, 47, 0.8);
}

.health-collapse
  .ant-collapse-item
  .ant-collapse-header
  .ant-collapse-extra
  > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.health-collapse
  .ant-collapse-item
  .ant-collapse-header
  .ant-collapse-extra
  .ant-space-item
  h3 {
  color: var(--primary-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  /* 155.556% */
}

.health-collapse .ant-collapse-item .ant-collapse-content {
  border: none;
  padding: 0 32px 32px 32px;
}

.health-collapse
  .ant-collapse-item
  .ant-collapse-content
  .ant-collapse-content-box {
  padding: 24px 16px;
  border-radius: 4px;
  background: #f9f9f9;
}

.health-collapse
  .ant-collapse-item
  .ant-collapse-content
  .ant-collapse-content-box
  h4 {
  margin: 0 0 8px 0;
  color: var(--primary-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.health-collapse
  .ant-collapse-item
  .ant-collapse-content
  .ant-collapse-content-box
  p {
  margin: 0;
  color: var(--primary-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

/* Progress Bar CSS */
.custom-progress-bar {
  position: relative;
  margin-bottom: 30px;
}

.custom-progress-bar .level-1 {
  width: 10%;
}

.custom-progress-bar .level-2 {
  width: 15%;
}

.custom-progress-bar .level-3 {
  width: 16%;
}

.custom-progress-bar .level-4 {
  width: 20%;
}

.custom-progress-bar .level-5 {
  width: calc(35% - 33px);
}

.custom-progress-bar .bar-line {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  border-radius: 100px;
  gap: 5px;
}
.small-progress-bar .bar-line {
  gap: 0;
  max-width: 207px;
  width: 100%;
  height: 6px;
}

.custom-progress-bar .bar-line .level {
  height: 10px;
  position: relative;
}

.small-progress-bar .bar-line .level {
  height: 7px;
  position: relative;
}
.custom-progress-bar.small-progress-bar .bar-line .indicator {
  width: 20px;
  height: 20px;
}

.custom-progress-bar .bar-line .level-1 {
  height: 10px;
  background-color: #b92deb;
}

.custom-progress-bar .bar-line .pre-first-level {
  border-radius: 100px 0 0 100px;
}

.custom-progress-bar .bar-line .level-2 {
  height: 10px;
  background-color: #97dfa2;
}

.custom-progress-bar .bar-line .level-3 {
  height: 10px;
  background-color: #ffcc00;
}

.custom-progress-bar .bar-line .level-4 {
  height: 10px;
  background-color: #ffb524;
}

.custom-progress-bar .bar-line .level-5,
.post-last-level {
  height: 10px;
  background-color: #ff4237;
}

.custom-progress-bar .bar-line .post-last-level {
  border-radius: 0 100px 100px 0;
}

.custom-progress-bar .bar-line .indicator {
  position: absolute;
  top: -7px;
  left: 0;
  box-shadow: 0px 0px 3.1630661487579346px 0px #0e25231a;
  border: 3px solid #ffffff;
  background: inherit;
  width: 24px;
  height: 24px;
  border-radius: 100px;
  margin-left: -12px;
}

.custom-progress-bar .bar-line-text {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  opacity: 0.6;
  padding-top: 3px;
}
.custom-progress-bar .bar-line-text > div {
  height: 36px;
}

.bar-line-sparator > div > span {
  position: relative;
  right: -13.5px;
  display: inline-block;
  width: 23px;
  text-align: center;
}

.bar-line-sparator > div {
  text-align: end;
}

.bar-line-sparator > div > span:before {
  content: "";
  border-right: 1px dashed #ccc;
  height: 40px;
  width: 1px;
  display: block;
  position: absolute;
  top: -44px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.custom-progress-bar .bar-line-sparator {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  text-align: right;
  color: var(--app-placeholder-text, #547470);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
  opacity: 0.6;
}

/* .custom-progress-bar .bar-line-sparator .level-1 .count,
.custom-progress-bar .bar-line-sparator .level-2 .count,
.custom-progress-bar .bar-line-sparator .level-3 .count,
.custom-progress-bar .bar-line-sparator .level-4 .count,
.custom-progress-bar .bar-line-sparator .level-5 .count {
  position: relative;
  right: -10px;
  top: 0;
  padding-top: 7px;
} */

/* .custom-progress-bar .bar-line-sparator .level-1 .count:after,
.custom-progress-bar .bar-line-sparator .level-2 .count:after,
.custom-progress-bar .bar-line-sparator .level-3 .count:after,
.custom-progress-bar .bar-line-sparator .level-4 .count:after,
.custom-progress-bar .bar-line-sparator .level-5 .count:after {
  content: '';
  position: absolute;
  top: -18px;
  left: 50%;
  transform: translate(-50%, 0);
  height: 24px;
  width: 1px;
  border-left: 1px dashed #D1DAD6;
} */

.small-progress-bar {
  position: relative;
  /* background-color: #CCC; */
  border-radius: 100px;
  width: 207px;
  height: auto;
  margin: 15px 10px 0 0;
}

.small-progress-bar .bar-pointer {
  position: absolute;
  background-color: #97dfa2;
  width: 44px;
  height: 6px;
  top: 0;
  left: 13%;
}

.small-progress-bar .bar-pointer:after {
  content: "";
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translate(-50%, 0);
  box-shadow: 0px 0px 3.1630661487579346px 0px #0e25231a;
  border: 2px solid #ffffff;
  background: #97dfa2;
  width: 10px;
  height: 10px;
  border-radius: 100px;
}

.small-progress-bar.very-high-risk .bar-pointer {
  width: 63px;
  background-color: #b92deb;
  left: auto;
  right: 0;
  border-radius: 0 100px 100px 0;
}

.small-progress-bar.very-high-risk .bar-pointer:after {
  background: #b92deb;
  left: 37%;
}

.small-progress-bar.optimal .bar-pointer {
  background-color: #97dfa2;
  left: 13%;
}

.small-progress-bar.optimal .bar-pointer:after {
  background: #97dfa2;
}

.small-progress-bar.low-risk .bar-pointer,
.small-progress-bar.low-risk .bar-pointer:after {
  background-color: #ffcc00;
}

.small-progress-bar.low-risk .bar-pointer {
  width: 30px;
  left: 28%;
}

.small-progress-bar.low-risk .bar-pointer:after {
  left: 70%;
}

.small-progress-bar.moderate-risk .bar-pointer,
.small-progress-bar.moderate-risk .bar-pointer:after {
  background-color: #ffb524;
}

.small-progress-bar.moderate-risk .bar-pointer {
  width: 34px;
  left: 56%;
}

.small-progress-bar.moderate-risk .bar-pointer:after {
  left: 85%;
}

.small-progress-bar.high-risk .bar-pointer,
.small-progress-bar.high-risk .bar-pointer:after {
  background-color: #ff4237;
}

.small-progress-bar.high-risk .bar-pointer {
  left: 44%;
}

/* FAQ Accordion CSS */
.faq-accordion {
  background: transparent;
  border: none;
  border-radius: 0;
}

.faq-accordion .ant-collapse-item {
  border-radius: 0 !important;
  border: none !important;
}

.faq-accordion .ant-collapse-item .ant-collapse-header {
  padding: 5px 0;
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
}

.faq-accordion .ant-collapse-item .ant-collapse-content {
  background: none;
  border: none;
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.faq-accordion
  .ant-collapse-item
  .ant-collapse-content
  .ant-collapse-content-box {
  padding: 6px 0;
}

.faq-accordion
  .ant-collapse-item
  .ant-collapse-content
  .ant-collapse-content-box
  p {
  margin-top: 0;
}
.show-hide-source {
  font-weight: 700;
  font-size: 13.33px;
  margin-left: 10px;
  cursor: pointer;
}
/* Ant Tag CSS */
.ant-tag.ant-tag-gold {
  color: #d68d00;
  background: rgba(255, 181, 36, 0.3);
}

.ant-tag.ant-tag-green {
  color: #4b9656;
  background: rgba(151, 223, 162, 0.3);
}

.ant-tag.ant-tag-purple {
  color: #8d0dba;
  background: rgba(185, 45, 235, 0.3);
}

.ant-tag.ant-tag-red {
  color: #f50d00;
  background: rgba(255, 66, 55, 0.3);
}

.ant-tag.ant-tag-warning {
  color: #98702f;
  background: rgba(255, 204, 0, 0.3);
}

.ant-tag.bigger {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  /* 155.556% */
  border-radius: 6px;
  padding: 0 5px;
  border-color: transparent;
}

/* Heart Health Measures Section CSS */
.heart-health-main-section {
  padding-top: 45px;
  padding-bottom: 45px;
  max-width: 1120px;
  margin: 0 auto;
}

.heart-health-main-section .block-header {
  max-width: 960px;
  margin: 0 auto;
}

.heart-health-main-section .block-header .block-title {
  color: #1d1d1d;
  font-size: 36px !important;
  font-weight: 600;
  line-height: 44px;
  /* 122.222% */
  letter-spacing: -0.72px;
}

.heart-health-measures-section {
  display: flex;
  background-color: #edecec;
}

.heart-health-measures-section-small {
  display: none;
}

.heart-health-measures-section .left-side {
  position: relative;
  flex: 1;
  padding: 60px 20px 60px;
}

.heart-health-measures-section .left-side img {
  width: 100%;
  height: auto;
}

.heart-health-measures-section .right-side {
  width: 220px;
  background-color: #e2e2e2;
  padding: 55px 16px 55px 16px;
}

.heart-health-measures-section .right-side .title {
  color: #626262;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
}

.heart-health-modal {
  position: absolute;
  top: 32px;
  left: 32px;
  width: calc(100% - 64px);
  border-radius: 8px;
  /* border: 2px solid #FF9501; */
  border: 2px solid #ffffff;
  background: #fff;
  display: inline-flex;
  padding: 43px 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.heart-health-modal .close-btn {
  position: absolute;
  top: 8px;
  right: 6px;
  border: none;
  background: transparent;
  font-size: 20px;
  color: #000;
  padding: 0 7px;
}

.heart-health-modal .heart-health-modal-body h3 {
  margin: 0;
  color: #1d1d1d;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.heart-health-modal .heart-health-modal-body p {
  color: #18322f;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.text-green-only {
  color: #176448;
}
.heart-health-modal .heart-health-modal-body .text-green {
  color: #176448;
}

.heart-health-modal .heart-health-modal-body .bottom-text {
  color: #176448;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
}

.icon-text-card {
  border-radius: 8px;
  border: 2px solid #e2e2e2;
  background: #fff;
  display: flex;
  padding: 16px 8px;
  margin: 14px 0;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.icon-text-card:hover,
.icon-text-card.active {
  border-color: #ff9301;
  color: #000;
}

/* Disease section start */
.disease-card {
  background-color: #ffffff;
  padding: 16px 16px 32px 16px;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 20px;
  height: 100%;
}

.disease-card .title {
  font-weight: 700;
  min-height: 48px;
  text-align: center;
}

.disease-card > img {
  max-width: 100%;
  margin-bottom: 64px;
  max-height: 112px;
}

.disease-card .disease-description {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  line-height: 20px;
  height: 100px;
  margin-bottom: 16px;
}

.disease-card p {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  line-height: 20px;
  margin: 0 0 8px 0;
}

.disease-card .img-content-wrapper {
  margin-bottom: 14px;
}

.disease-card .img-content-wrapper .img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.disease-card .img-content-wrapper img {
  max-height: 64px;
  height: 100%;
}

/* Disease section end */
/* Result summary section start */
.result-summary-section .content-title {
  font-size: 16px;
  font-weight: 700;
  color: #626262;
  margin: 0 0 16px 0;
}

.result-card {
  background-color: #eaecf5;
  border-radius: 4px;
  padding: 16px !important;
  margin-bottom: 16px;
  min-height: 98px;
}

.result-card.current-health-card {
  background-color: #fff;
  margin: 0 0 40px 0;
  min-height: 146px;
}

.current-health-card a {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #176448;
}

.result-card .content-title {
  color: #18322f;
  margin-bottom: 10px;
}

.current-health-card .content-title {
  margin-bottom: 16px;
}

.result-card .result-content {
  font-size: 12px;
  font-weight: 500;
  color: rgba(24, 50, 47, 0.8);
  margin: 10px 0 0 0;
}

.current-health-card .result-content {
  font-size: 14px;
  font-weight: 500;
  color: #1d1d1d;
  margin: 0 0 10px 0;
}

.result-card .result-value {
  font-weight: 700;
}

.result-card .ant-tag {
  font-size: 14px;
  font-weight: 700;
  margin-left: 8px;
  border-radius: 6px;
}

/* Result summary section end */
/* Endless healthplug section start */
.endless-healthplug-section {
  background-color: #18322f;
  padding: 80px;
}

.endless-healthplug-section .heart-health-main-section {
  padding: 0;
}

.endless-health-plan-card {
  padding: 30px;
  position: relative;
}

.endless-health-plan-card img {
  max-height: 358px;
  max-width: 100%;
  margin-bottom: 60px;
}

.endless-health-plan-card .description {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 25px;
  text-align: center;
}

.endless-health-plan-card .title {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.endless-healthplug-section .cta-btn {
  color: #18322f;
  border: 2px solid #18322f;
  background-color: transparent;
  font-size: 18px;
  font-weight: 700;
  min-height: 45px;
  border-radius: 8px;
}

.endless-health-plan-card .cta-btn:hover {
  opacity: 0.8;
}
.disease-card.endless-health-plan-card .cta-btn {
  border: none;
  color: #176448;
}

.disease-card.endless-health-plan-card .cta-btn .anticon {
  transform: rotate(45deg);
}

.endless-health-plan-card .cta-btn {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.endless-healthplug-section .block-header .cta-btn {
  color: #18322f;
  background-color: #f5f4f2;
  margin-top: 32px;
}

.endless-healthplug-section .block-header .cta-btn:hover {
  /* color: #F5F4F2; */
  border-color: #f5f4f2;
  /* opacity: 0.8; */
}

.endless-healthplug-section .block-header > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #f5f4f2;
}

.endless-healthplug-section .block-header {
  padding-bottom: 64px;
}

.endless-healthplug-section .block-img-wrapper {
  justify-content: flex-start !important;
  margin-bottom: 16px;
}

.endless-healthplug-section .block-img-wrapper span {
  font-size: 28px;
  font-family: "EB Garamond", serif;
  font-style: italic;
  font-weight: 500;
}

.endless-healthplug-section .block-img-wrapper img {
  height: 39px;
  max-width: 100%;
  margin-right: 7px;
}

.endless-healthplug-section .block-header .block-title {
  color: #f5f4f2;
}

@media screen and (max-width: 1280px) {
  .disease-card.endless-health-plan-card .cta-btn {
    font-size: 13px;
  }
}

@media screen and (max-width: 1023.9px) {
  .endless-healthplug-section .block-header > div {
    flex-wrap: wrap;
  }

  .endless-healthplug-section .block-title {
    margin-bottom: 20px !important;
  }
  .disease-card.endless-health-plan-card .cta-btn {
    font-size: 18px;
  }
}

@media screen and (max-width: 767.9px) {
  .endless-healthplug-section .block-img-wrapper img {
    margin-bottom: 10px;
  }

  .endless-healthplug-section {
    padding: 20px;
  }
}

/* Endless healthplug section end */

/* Medinfo section start */
.medinfo-table {
  margin: 32px 0;
}

.medinfo-table .ant-table-thead th {
  background-color: #d1dad6 !important;
  color: #000 !important;
  font-size: 20px;
  font-weight: 600;
  border-top: 1px solid #626262;
  padding: 20px 12px !important;
}

.medinfo-table td {
  color: #000 !important;
  font-size: 16px;
  font-weight: 600;
  padding: 12px !important;
}

.medinfo-table table > tr:last-child > td:first-child {
  border-bottom-left-radius: 8px !important;
  border-color: #000 !important;
}

.medinfo-table table > tr:last-child > td:last-child {
  border-bottom-right-radius: 8px !important;
}

.medinfo-table table {
  border-radius: 8px !important;
  background-color: #f5f4f2 !important;
  border-radius: 8px !important;
}

.medinfo-table table th,
.medinfo-table table td {
  border-color: #626262 !important;
}

.medinfo-table table tr:last-child td:last-child {
  border-bottom-right-radius: 8px !important;
}

.medinfo-table table tr:last-child td:first-child {
  border-bottom-left-radius: 8px !important;
}

.medinfo-table table tr td:first-child,
.medinfo-table table tr th:first-child {
  border-left: 1px solid #626262 !important;
}

/* Medinfo section end */
.d-none {
  display: none;
}

/* Life Style Change Advice section start */
.advice-item {
  display: flex;
  align-items: center;
  gap: 32px;
  margin-bottom: 64px;
}

.advice-item > div:last-child {
  min-height: 228px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.advice-item img {
  max-width: 368px;
  border-radius: 16px;
}

.advice-item .advice-title {
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 8px 0;
}

.advice-item .advice-title strong {
  color: #176448;
}

.advice-item .learn-more-btn {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  padding: 10px 16px;
  border-radius: 4px;
  background-color: #18322f;
  border-color: #fff;
  border-style: none;
  cursor: pointer;
}

@media screen and (max-width: 767.9px) {
  .advice-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    border-radius: 16px;
  }

  .advice-item img {
    /* display: block; */
    width: 100%; /* Set the image width to 100% of the container */
    height: auto; /* Maintain the image's aspect ratio */
    border-radius: 16px;
    /* Add other styles as needed */
  }
}

/* Need guidance section */
.need-guidance {
  display: flex;
  align-items: center;
  gap: 32px;
  margin-bottom: 64px;
  padding: 0;
  border-radius: 8px;
}
.need-guidance div {
  padding: 0 8px;
}

.need-guidance > div:last-child {
  min-height: 215px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding-right: 32px;
}

.need-guidance img {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0px;
}

.need-guidance .need-guidance-title {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 8px 0;
  font-style: normal;
}

.need-guidance .need-guidance-title strong {
  color: #176448;
}

.need-guidance .connect-btn {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  padding: 10px 16px;
  border-radius: 4px;
  background-color: #18322f;
  border-color: #fff;
  border-style: none;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.heart-health-main-section .need-guidance {
  padding: 0px;
  background: #fff;
}

.heart-health-main-section .need-guidance .img-wrap {
  max-width: 368px;
  width: 100%;
  border-radius: 12px;
  background: #d1dad6;
  height: 228.856px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heart-health-main-section .need-guidance .img-wrap img {
  max-width: 220px;
  width: 100%;
}

@media screen and (max-width: 767.9px) {
  .need-guidance {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    border-radius: 16px;
    min-height: 170px;
    padding-right: 8px;
  }

  .need-guidance > div:last-child {
    padding-right: 16px;
  }

  .need-guidance div {
    padding: 0 16px 0 16px;
    text-align: justify;
  }

  .need-guidance img {
    width: 100%;
    height: auto;
    /* border-radius: 16px; */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
  }
}

/* Disclaimer section start */
.disclaimer-section .block-title {
  margin-bottom: 12px;
}

.disclaimer-section .block-description {
  font-weight: 400;
  margin-bottom: 16px;
  color: #1d1d1d;
}

/* Disclaimer section end */

/* New css */

.dashboard-wrapper .vascular-wrap {
  background: #18322f;
  padding: 80px 160px;
  margin-bottom: 35px;
}

.dashboard-wrapper .vascular-wrap h3 {
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.72px;
  color: #fff;
}

.dashboard-wrapper .vascular-wrap p {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
}

.dashboard-wrapper .vascular-wrap .yet-para {
  margin-bottom: 20px;
}

.vascular-wrap .eh-list {
  list-style: none;
  padding: 0;
}

.vascular-wrap .eh-list li {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
}

.vascular-wrap .eh-list li::before {
  content: "";
  background: url("../../.././public/images/check.svg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 15px;
  display: flex;
  height: 15px;
  border-radius: 50%;
  margin-right: 7px;
}

.vascular-wrap .eh-list li span img {
  border-radius: 50%;
  height: 18px;
  width: 18px;
}

.heart-health-main-section .advice-item {
  padding: 32px;
  background: #fff;
}

.heart-health-main-section .advice-item .img-wrap {
  max-width: 368px;
  width: 100%;
  border-radius: 12px;
  background: #d1dad6;
  height: 228.856px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heart-health-main-section .advice-item .img-wrap img {
  max-width: 220px;
  width: 100%;
}

.common-label {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  margin: 0 0 8px 0;
}

.heart-health-main-section-large-screen {
  display: block;
}

.heart-health-main-section-small-screen {
  display: none;
}

/* Media query for smaller screens */
@media only screen and (max-width: 768px) {
  .dashboard-wrapper {
    padding: 30px 10px;
  }
  .dashboard-wrapper .vascular-wrap {
    padding: 40px 20px;
  }

  .dashboard-wrapper .vascular-wrap h3 {
    font-size: 24px;
    line-height: 32px;
  }

  .dashboard-wrapper .vascular-wrap p {
    font-size: 12px;
  }

  .vascular-wrap .eh-list li {
    font-size: 12px;
  }

  .vascular-wrap .eh-list li::before {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }

  .vascular-wrap .eh-list li span img {
    height: 14px;
    width: 14px;
  }

  .heart-health-main-section-img {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    text-align: center;
  }

  .risk-card .img {
    padding: 5px;
  }

  .risk-card .img img {
    width: 100%;
  }

  .risk-card .title {
    padding: 0 1rem; /* Adjust as needed */
  }

  .heart-health-main-section-large-screen {
    display: none;
  }

  .heart-health-main-section-small-screen {
    display: block;
  }

  /* Health Collapse CSS for Android */

  .health-collapse {
    background: transparent;
    border: none;
    border-radius: 0;
  }

  .health-collapse .ant-collapse-item {
    margin-bottom: 16px;
    background: #fff;
    border: none;
    border-radius: 4px;
    width: 100%;
  }

  .health-collapse .ant-collapse-item .ant-collapse-header {
    padding: 10px;
    /* margin-left: 32px; */
    /* margin-right: 20px; */
    display: flex;
    /* flex-direction: column; */
    align-items: right;
  }

  .health-collapse .ant-collapse-item .ant-collapse-header h3,
  .health-collapse .ant-collapse-item .ant-collapse-header h4,
  .health-collapse .ant-collapse-item .ant-collapse-header p {
    margin: 0;
    padding: 5px;
  }

  .health-collapse
    .ant-collapse-item
    .ant-collapse-header
    .ant-collapse-header-text {
    max-width: 300px;
  }

  .health-collapse
    .ant-collapse-item
    .ant-collapse-header
    .ant-collapse-header-text
    h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 100%;
    width: auto;
    color: #18322f;
    padding-left: 3px;
  }

  .health-collapse
    .ant-collapse-item
    .ant-collapse-header
    .ant-collapse-header-text
    p {
    font-size: 12px;
    font-weight: 500;
    /* line-height: 18px; */
    color: rgba(24, 50, 47, 0.8);
  }

  .health-collapse .ant-collapse-item .ant-collapse-header > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .health-collapse
    .ant-collapse-item
    .ant-collapse-header
    .ant-collapse-extra
    > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .health-collapse
    .ant-collapse-item
    .ant-collapse-header
    .ant-collapse-extra
    .ant-space-item
    h3 {
    color: var(--primary-color);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 155.556% */
  }

  .health-collapse .ant-collapse-item .ant-collapse-content {
    border: none;
    padding: 0 16px 16px 16px;
  }

  .health-collapse
    .ant-collapse-item
    .ant-collapse-content
    .ant-collapse-content-box {
    padding: 12px 10px;
    margin: 5px;
    border-radius: 4px;
    background: #f9f9f9;
  }

  .health-collapse
    .ant-collapse-item
    .ant-collapse-content
    .ant-collapse-content-box
    h4 {
    margin: 0 0 8px 0;
    color: var(--primary-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
  }

  .health-collapse
    .ant-collapse-item
    .ant-collapse-content
    .ant-collapse-content-box
    p {
    margin: 0;
    color: var(--primary-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
  }

  /* Progress Bar CSS for Android */

  .custom-progress-bar {
    position: relative;
    margin-bottom: 20px;
  }

  .custom-progress-bar .level-1 {
    width: 10%;
  }

  .custom-progress-bar .level-2 {
    width: 15%;
  }

  .custom-progress-bar .level-3 {
    width: 16%;
  }

  .custom-progress-bar .level-4 {
    width: 20%;
  }

  .custom-progress-bar .level-5 {
    width: calc(35% - 33px);
  }

  .custom-progress-bar .bar-line {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    border-radius: 100px;
    gap: 5px;
  }

  .small-progress-bar .bar-line {
    gap: 0;
    max-width: 150px;
    width: 100%;
    height: 6px;
  }

  .custom-progress-bar .bar-line .level {
    height: 8px;
    position: relative;
  }

  .small-progress-bar .bar-line .level {
    height: 6px;
    position: relative;
  }

  .custom-progress-bar.small-progress-bar .bar-line .indicator {
    width: 16px;
    height: 16px;
  }

  .custom-progress-bar .bar-line .level-1 {
    height: 8px;
    background-color: #b92deb;
  }

  .custom-progress-bar .bar-line .pre-first-level {
    border-radius: 100px 0 0 100px;
  }

  .custom-progress-bar .bar-line .level-2 {
    height: 8px;
    background-color: #97dfa2;
  }

  .custom-progress-bar .bar-line .level-3 {
    height: 8px;
    background-color: #ffcc00;
  }

  .custom-progress-bar .bar-line .level-4 {
    height: 8px;
    background-color: #ffb524;
  }

  .custom-progress-bar .bar-line .level-5,
  .post-last-level {
    height: 8px;
    background-color: #ff4237;
  }

  .custom-progress-bar .bar-line .post-last-level {
    border-radius: 0 100px 100px 0;
  }

  .custom-progress-bar .bar-line .indicator {
    position: absolute;
    top: -6px;
    left: 0;
    box-shadow: 0px 0px 2px 0px #0e25231a;
    border: 2px solid #ffffff;
    background: inherit;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    margin-left: -10px;
  }

  .custom-progress-bar .bar-line-text {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    font-size: 6px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    opacity: 0.7;
    padding-top: 5px;
  }

  .custom-progress-bar .bar-line-text > div {
    height: 30px;
  }

  .bar-line-sparator > div > span {
    position: relative;
    right: -9.5px;
    display: inline-block;
    width: 18px;
    text-align: center;
  }

  .bar-line-sparator > div {
    text-align: end;
  }

  .bar-line-sparator > div > span:before {
    content: "";
    border-right: 1px dashed #ccc;
    height: 32px;
    width: 1px;
    display: block;
    position: absolute;
    top: -40px;
    left: 45%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .custom-progress-bar .bar-line-sparator {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px;
    text-align: right;
    color: var(--app-placeholder-text, #547470);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    /* 150% */
    opacity: 0.6;
  }
  .small-progress-bar {
    position: relative;
    /* background-color: #CCC; */
    border-radius: 100px;
    width: 150px;
    height: auto;
    margin: 15px 10px 0 0;
  }

  .small-progress-bar .bar-pointer {
    position: absolute;
    background-color: #97dfa2;
    width: 30px;
    height: 6px;
    top: 0;
    left: 20%;
  }

  .small-progress-bar .bar-pointer:after {
    content: "";
    position: absolute;
    top: -4px;
    left: 50%;
    transform: translate(-50%, 0);
    box-shadow: 0px 0px 2px 0px #0e25231a;
    border: 2px solid #ffffff;
    background: #97dfa2;
    width: 8px;
    height: 8px;
    border-radius: 100px;
  }

  .small-progress-bar.very-high-risk .bar-pointer {
    width: 42px;
    background-color: #b92deb;
    left: auto;
    right: 0;
    border-radius: 0 100px 100px 0;
  }

  .small-progress-bar.very-high-risk .bar-pointer:after {
    background: #b92deb;
    left: 25%;
  }

  .small-progress-bar.optimal .bar-pointer {
    background-color: #97dfa2;
    left: 20%;
  }

  .small-progress-bar.optimal .bar-pointer:after {
    background: #97dfa2;
  }

  .small-progress-bar.low-risk .bar-pointer,
  .small-progress-bar.low-risk .bar-pointer:after {
    background-color: #ffcc00;
  }

  .small-progress-bar.low-risk .bar-pointer {
    width: 18px;
    left: 35%;
  }

  .small-progress-bar.low-risk .bar-pointer:after {
    left: 75%;
  }

  .small-progress-bar.moderate-risk .bar-pointer,
  .small-progress-bar.moderate-risk .bar-pointer:after {
    background-color: #ffb524;
  }

  .small-progress-bar.moderate-risk .bar-pointer {
    width: 20px;
    left: 55%;
  }

  .small-progress-bar.moderate-risk .bar-pointer:after {
    left: 80%;
  }

  .small-progress-bar.high-risk .bar-pointer,
  .small-progress-bar.high-risk .bar-pointer:after {
    background-color: #ff4237;
  }

  .small-progress-bar.high-risk .bar-pointer {
    left: 45%;
  }

  .heart-health-measures-section {
    display: none;
  }

  .heart-health-measures-section-small {
    display: block;
    flex-direction: column;
    background-color: #edecec;
  }

  .heart-health-measures-section-small .left-side {
    position: relative;
    flex: 1;
    padding: 60px 20px 60px;
  }

  .heart-health-measures-section-small .left-side img {
    width: 100%;
    height: auto;
  }

  .heart-health-measures-section .right-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 220px;
    background-color: #e2e2e2;
    padding: 55px 16px 55px 16px;
  }

  .heart-health-measures-section .right-side a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px; /* Add space between each link */
  }

  /* .icon-text-card {
    border-radius: 8px;
    border: 2px solid #E2E2E2;
    background: #FFF;
    display: flex;
    padding: 16px 8px;
    margin: 14px 0;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  } */

  .heart-health-measures-section-small .right-side .title {
    color: #626262;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
  }

  /* Assuming you are targeting a general phone screen size */
  /* You may need to customize further based on specific device sizes */

  /* Common styles for modal container */
  .heart-health-modal {
    position: absolute;
    top: 90%;
    left: 0;
    width: 100%;
    border-radius: 8dp;
    border: 2dp solid #ffffff;
    background: #fff;
    /* padding: 16dp; */
    /* margin: 8dp; */
    z-index: 999; /* Adjust z-index based on your layout */
  }

  /* Close button styles */
  .heart-health-modal .close-btn {
    position: absolute;
    top: 4dp;
    right: 4dp;
    border: none;
    background: transparent;
    font-size: 14sp;
    color: #000;
    padding: 0 4dp;
  }

  /* Modal body heading styles */
  .heart-health-modal h3 {
    margin: 0;
    color: #1d1d1d;
    font-size: 16sp;
    font-weight: 700;
    line-height: 24sp;
  }

  /* Modal body paragraph styles */
  .heart-health-modal p {
    color: #18322f;
    font-size: 14sp;
    font-weight: 400;
    line-height: 20sp;
  }
}
